.nutritional-column {
  color: $textstandard;
  width: 20vw;
  max-width: 23rem;
  min-height: 85vh;
  padding-left: 1rem;
  border-left: 1px dotted lighten($textstandard, 30%);
  @include breakpoint(xs) {
    border-left: none;
    border-top: 1px dotted lighten($textstandard, 10%);
    padding-top: 2rem;
    margin-top: 2rem;
    padding-left: 0;
    width: 100%;
    max-width: 100%;
    min-height: none;
  }
  .certifications {
    margin-top: 1rem;
    .logos {
      margin-bottom: 1rem;
      @include flexbox(0 0 auto, row, flex-start, flex-start);
      flex-wrap: wrap;
      img {
        max-width: 6rem;
        margin: 0 1rem 0 0;
      }
    }
  }
}

.nutrition-block {
  border: 0.38rem solid $textstandard;
  padding: 0.6rem;
  margin-bottom: 2rem;
  header {
    font-weight: 800;
    font-size: 2.1rem;
    padding-bottom: 0.8rem;
  }
  .serving-size {
    font-size: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 0.2rem solid $textstandard;
  }
  .nutrition-row {
    @include flexbox(0 0 auto, row, flex-start, flex-start);
    width: 100%;
    border-bottom: 1px solid $textstandard;
    padding: 0.1rem 0;
    .label {
      font-size: 1.1rem;
      margin-bottom: 0.4rem;
      font-weight: 500;
      margin-right: auto;
      &.align-left {
        margin-left: auto;
        margin-right: 0;
      }
    }
    .data {
      font-size: 1.2rem;
      font-weight: 700;
      margin-left: 0.35rem;
    }
    .dv-data {
      font-size: 1.2rem;
      font-weight: 500;
      margin-right: 0.4rem;
    }
    &.dv-note {
      display: block;
      border: none;
      padding: 0.5rem 0.2rem 0.3rem 0;
      span {
        margin-left: 0.3rem;
        font-weight: 700;
      }
    }
    &.no-border {
      border-bottom: none;
    }
    &.calories {
      padding: 0.8rem 0 0 0;
      margin-bottom: 0.5rem;
      .dv {
        font-weight: 600;
        font-size: 1rem;
        padding-top: 0.45rem;
        text-align: right;
      }

      .label {
        width: 76%;
        padding-bottom: 0.6rem;
        font-weight: 800;
        font-size: 1.8rem;
        margin-right: auto;
        border-bottom: 0.3rem solid $textstandard;
      }
      .data {
        font-size: 1.8rem;
        font-weight: 800;
        &.description {
          font-weight: 400;
          line-height: 1.2;
        }
      }
    }

    &.section-level-1 {
      margin: 0.4rem 0;
      border-top: none;
      .label {
        margin-bottom: 0.4rem;
        font-weight: 800;
        font-size: 1.4rem;
        margin-right: auto;
      }
      .data {
        font-size: 1.4rem;
        font-weight: 800;
        &.description {
          font-weight: 400;
          line-height: 1.2;
        }
      }
    }
    &.divider-bottom {
      border-width: 0.4rem;
      // margin-bottom: 0.7rem;
    }
    &.sub-section {
      .label {
        margin-left: 1rem;
        // font-style: italic;
      }
    }
  }
  @include breakpoint(xs) {
    &.adjusted-for-source {
      max-width: 45vw;
    }
  }
}
