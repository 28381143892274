.error-page {
  @include flexbox(0 0 auto, row, center, center);
  transition: all ease-in-out 300ms;
  animation: fadeIn ease 500ms;
  .error-panel {
    color: darken($syscored, 20%);
    text-align: center;
    border: 1px solid lighten($textstandard, 30%);
    padding: 2rem 3rem;
    border-radius: 0.6rem;
    animation: fadeIn ease 1000ms;
  }
  h1 {
    font-size: 3rem;
    margin: 0.4em 0;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .icon {
    margin-right: 0.5rem;
  }
}
