.tags {
  @include flexbox(0 0 auto, row, flex-start, flex-start);
  flex-wrap: wrap;
  .tag {
    margin: 0 0.8rem 0.5rem 0;
  }
}
.tag {
  padding: 0.3rem 0.8rem;
  font-size: 1rem;
  border-radius: 2rem;
  background: $syscoblue;
  color: $textalternative;
  transition: all ease-in-out 150ms;
  &:hover {
    filter: brightness(1.1);
  }
  &.status {
    background: $textalternative;
    color: $syscogreen;
    border: 1px solid $syscogreen;
  }
  &.status-inactive {
    background: $textalternative;
    color: $syscored;
    border: 1px solid $syscored;
  }
  &.highlight {
    background: $syscored;
  }
  &.local {
    background: $bluegrey2;
  }
  &.allergens {
    background: $syscoyellow;
  }
  &.attributes {
    background: $syscoorange;
  }
  &.diet {
    background: lighten($syscogreen, 15%);
  }
  &.claims {
    background: $syscoblue;
  }
}
