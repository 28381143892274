@import 'actionbuttons/actionbuttons';
@import 'tags/tags';
@import 'mini-header-panel/mini-header-panel';
@import 'media-column/media-column';
@import 'info-column/info-column';
@import 'nutritional-column/nutritional-column';
@import 'errors/_errors';
@import 'print/_print';

.product-sheet-wrapper {
  @include flexbox(1 1 auto, row, flex-start, flex-start);
  // overflow: auto;
  padding: 0.5rem 2rem;
  animation: fadeIn ease 500ms;
  @include breakpoint(xs) {
    padding: 0.5rem 1.5rem;
    display: block;
  }
}
//Print Button
.product-sheet-print-wrap {
  margin-right: 1rem;
  .product-sheet-print {
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: relative;
    bottom: 2px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%0A%3Csvg height='20px' width='20px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' x='0px' y='0px'%3E%3Ctitle%3Eprint%3C/title%3E%3Cg data-name='10'%3E%3Cpath d='M24,23H23v6l-1,1H10L9,29V23H8V21H24Zm5-11H3L2,13V25l1,1H8V24H7V20H25v4H24v2h5l1-1V13ZM23,8,22,7H10L9,8v3H23Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    &:hover {
      fill: #008ace;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20px' width='20px' fill='%23008ace' viewBox='0 0 32 32' x='0px' y='0px'%3E%3Ctitle%3Eprint%3C/title%3E%3Cg data-name='10'%3E%3Cpath d='M24,23H23v6l-1,1H10L9,29V23H8V21H24Zm5-11H3L2,13V25l1,1H8V24H7V20H25v4H24v2h5l1-1V13ZM23,8,22,7H10L9,8v3H23Z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
.figure-text {
  margin: 0;
  display: block;
}
