/* Set of embedded font file definitions
        Fonts used:
          - Myriad Pro
          
    Please use typekit whenever possible
*/

///// Enable the following if you wish to use offline fonts, make sure to comment the typekit import below if you do so.
// @import '_myriadpro';

//Typekit import
// @import url('https://use.typekit.net/wvb1lqs.css');
@import url('https://use.typekit.net/dah4mey.css');
