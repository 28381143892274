.mini-header-panel {
  @include flexbox(0 0 auto, row, flex-end, flex-start);
  width: 100%;
  padding: 0.5rem 2rem;
  @include breakpoint(xs) {
    @include flexbox(0 0 auto, row, flex-start, flex-start);
    padding: 1rem 1rem;
  }
  .language {
    // width: 100%;
    @include flexbox(0 0 auto, row, flex-end, flex-start);
    .lang-selected {
      font-weight: bold !important;
      font-size: 15px;
    }
    a {
      font-weight: 400;
      cursor: pointer;
      margin-inline: 0.5rem;
    }
  }
  .report-link {
    @include flexbox(0 0 auto, row, flex-end, flex-start);
    margin-right: 1.5rem;
    a {
      &:link {
        color: $textstandard;
      }
      &:hover {
        color: $syscoblue;
      }

      font-weight: 600;
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }
}
