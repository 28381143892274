.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $faded-background-light;
  z-index: $bringToTop;
  display: flex;
  justify-content: center;
  align-items: center;

  .ring-loader {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 4.2rem;
      height: 4.2rem;
      margin: 0.53rem;
      border: 0.53rem solid $syscoblue;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $syscoblue transparent transparent transparent;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
