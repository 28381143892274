.info-column {
  color: $textstandard;
  width: 53vw;
  margin-right: 3vw;
  @include breakpoint(xs) {
    width: 100%;
  }

  .title-description {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .overview {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    a {
      font-size: 1.1rem;
      cursor: pointer;
      font-weight: 700;
      margin-left: 0.8rem;
    }
  }
  .unabreviated-description {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
  }

  .read-more-less--more,
  .read-more-less--less {
    color: $textstandard;
  }
}
.info-row {
  @include flexbox(0 0 auto, row, flex-start, flex-start);
  flex-wrap: wrap;
  .data-block {
    padding-right: 2rem;
    margin-bottom: 1.5rem;
    .label {
      opacity: 0.6;
      text-transform: uppercase;
      font-size: 1.1rem;
      margin-bottom: 0.4rem;
      font-weight: 500;
    }
    .data {
      font-size: 1.1rem;
      font-weight: 600;
      &.description {
        font-weight: 400;
        line-height: 1.2;
      }
      .inline-image {
        float: left;
        width: 12rem;
      }
    }
  }
  &.inline-labels {
    .data-block {
      @include flexbox(0 0 auto, row, flex-start, flex-start);
      .label {
        padding-right: 0.7rem;
        margin-bottom: 0;
      }
    }
  }
  &.column-labels {
    flex-wrap: nowrap;

    .data-block-group {
      margin-left: 1rem;
      margin-bottom: 1.5rem;
      padding-left: 1rem;
      border-left: 1px dotted lighten($textstandard, 30%);
      .data-block:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    @include flexbox(0 0 auto, row, flex-start, center);
    opacity: 0.6;
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px dotted lighten($textstandard, 30%);
    font-style: italic;
    height: 3rem;
    &.remove-border-padding {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }
  &.brand-manufacturer-wrapper {
    border-bottom: 1px dotted lighten($textstandard, 30%);
    margin-bottom: 1.1rem;
    .data {
      font-size: 1.7rem;
      font-weight: 500;
    }
  }
}
.item-about-brand-img {
  padding-right: 10px;
  padding-bottom: 10px;
}

.fandb-items-wrap {
  padding-bottom: 15px;
  padding-left: 20px;
  width: 100%;
  //border-bottom: 1px dotted #eaeaea;
  margin-bottom: 1.1rem;
  .label {
    padding-bottom: 5px;
    opacity: 0.6;
    text-transform: capitalize;
    font-size: 1rem;
    margin-bottom: 0.4rem;
    font-weight: 500;
  }
}
// Tool tip styles for Feature and benefits
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.info-column-attributes-divider {
  border-bottom: 1px dotted #4d4d4d;
  margin-bottom: 1.1rem;
  display: block;
}

.raw-text {
  white-space: pre-wrap;
}
