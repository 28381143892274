.btn {
  position: relative;
  border-radius: 10rem;
  font-weight: 600;
  font-size: 0.9rem;
  margin-right: 1rem;
  padding: 0 1rem;
  height: 1.9rem;
  line-height: 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.21);
  i {
    margin-right: 0.4rem;
    // font-size: 0.9rem;
    &.dropdown-arrow {
      margin: 0 -0.3rem 0 0.4rem;
    }
  }
  &.unrounded {
    border-radius: 0.4rem;
  }
  &.no-shadow {
    box-shadow: none;
  }
  &.icon-only {
    i {
      margin-right: 0;
    }
  }
  &.no-flex {
    display: inline-block;
  }
  &.empty-btn {
    background-color: transparent;
    border-color: transparent;
    color: $textstandard;
    opacity: 0.7;
    box-shadow: none;
    margin-right: 0;
    font-size: 1.3rem;
    border-left: 1px grey dotted;
    border-radius: 0;
    &:active {
      background-color: $background;
      opacity: 0.9;
    }
    i {
      margin-right: 0;
    }
  }
  &.notes-btn {
    background-color: transparent;
    border-color: transparent;
    color: $textstandard;
    opacity: 0.7;
    box-shadow: none;
    margin-right: 0;
    font-size: 1.3rem;
    border-radius: 0;
    &:active {
      background-color: $background;
      opacity: 0.9;
    }
    i {
      margin-right: 0;
    }
  }
}

//Batch list screen
.green-action-btn,
.create-new-btn {
  background-color: $syscogreen;
  border-color: $syscogreen;
  color: $textalternative;
  &:active {
    background-color: darken($syscogreen, 5%) !important;
  }
  &:hover,
  &:focus {
    background-color: lighten($syscogreen, 15%) !important;
    border-color: $syscogreen;
    color: $textalternative;
  }
  &.invert {
    background-color: $textalternative;
    color: $syscogreen;
    &:hover,
    &:focus,
    &:active {
      color: $textalternative;
    }
  }
}
.red-action-btn {
  background-color: $syscored;
  border-color: $syscored;
  color: $textalternative;
  &:active {
    background-color: darken($syscored, 5%) !important;
  }
  &:hover,
  &:focus {
    background-color: lighten($syscored, 15%) !important;
    border-color: $syscored;
    color: $textalternative;
  }
  &.invert {
    background-color: $textalternative;
    color: $syscored;
    &:hover,
    &:focus,
    &:active {
      color: $textalternative;
    }
  }
}
.white-action-btn {
  background-color: $background;
  border-color: $syscoblue;
  color: $syscoblue;
  &:active {
    background-color: darken($background, 3%) !important;
  }
  &:hover {
    background-color: $syscoblue;
    color: $background;
  }
}
.cancel-btn {
  background-color: transparent;
  border-color: $textstandard;
  color: $textstandard;
  opacity: 0.7;
  &:active {
    background-color: $background;
    opacity: 0.9;
  }
}

.back-btn {
  background-color: transparent;
  border-color: transparent;
  color: $textstandard;
  opacity: 0.7;
  box-shadow: none;
  &:active {
    background-color: $background;
    opacity: 0.9;
  }
}

.approval-note {
  position: absolute;
  color: $textstandard;
  margin-top: 2rem;
  margin-left: 4.5rem;
  font-weight: 500;
  opacity: 0.7;
}
.modal-confirm-label {
  @include flexbox(0 0 auto, row, flex-start, center);
  font-size: 1.2rem;
  padding: 1rem 0;
  .sub {
    width: 70%;
  }
  .icon {
    color: $syscoorange;
    font-size: 1.9rem;
    padding: 1rem 1rem 1rem 0;
  }
}
.modal-btn-row {
  @include flexbox(0 0 auto, row, flex-end);
  .btn {
    margin: 0.3rem;
    font-size: 1rem;
  }
  .dotted-connector {
    @include bg-border(center);
    width: 1.5rem;
    height: 1rem;
    margin-right: 0;
  }
}
