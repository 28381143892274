//Print PDF
@media print {
  @page {
    size: auto !important;
    size: portrait !important;
    margin: 2mm 1mm;
  }
  body {
    font-family: myriad-pro, sans-serif !important;
    height: initial !important;
    -webkit-print-color-adjust: exact;
    zoom: 0.85 !important;
    -moz-transform: scale(0.95, 0.85); /* Firefox */
    -moz-transform-origin: top;
    // -webkit-print-color-adjust: exact; /*chrome & webkit browsers*/
    color-adjust: exact; /*firefox & IE */
  }
  .product-sheet-wrapper {
    width: auto;
    display: block;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: visible;
    // padding: 5px 10px;
  }
  .wrapper {
    height: auto;
    width: 100%;
  }
  .media-column {
    float: left;
    width: 28%;
    // height: 100%;
    padding: 10px;
    position: relative !important;
    top: 20px !important;
    .product-images {
      zoom: 0.75;
      display: inline-block;
    }
    .specification-sheets {
      display: inline-block;
      width: 100%;
    }
    .msds-sheets {
      display: inline-block;
      width: 100%;
    }
    .recipes {
      display: inline-block;
      width: 100%;
    }
  }
  .info-column {
    display: inline-block;
    width: 30%;
    // height: 100%;
    padding: 10px;
    // margin-bottom: 10px !important;
  }
  .nutritional-column {
    float: right;
    width: 29%;
    // height: 100%;
    padding: 10px;
    padding-left: 1rem;
    border-left: 1px dotted #4d4d4d;
    border-top: none;

    position: relative !important;
    bottom: 25px !important;
  }
  .mobile-sheets {
    display: none !important;
  }
  .media-column .product-images .main {
    width: auto;
    height: auto;
    padding: 7px;
  }
  .main-img {
    max-width: none !important;
    max-height: none !important;
    width: 100% !important;
    height: 40vw !important;
  }
  .video-thumbnail-tile {
    width: auto;
    height: auto;
  }
  .media-column .product-images .main .main-img img {
    width: 100%;
    height: 100%;
  }
  // end
  .media-column .product-images ul {
    width: 92%;
  }
  .media-column .product-images ul li {
    width: 11vw;
    height: 11vw;
  }
  .product-images .main {
    zoom: 0.85 !important;
    width: 97% !important;
    // -moz-transform: scale(0.75, 0.75); /* Firefox */
    // -moz-transform-origin: 0px 170px;
  }
  .figure-text {
    margin: 0;
    page-break-after: avoid !important;
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    display: block;
  }
  // Firefox
  @supports (-moz-appearance: none) {
    .product-images .main {
      width: 90% !important;
    }
    .nutritional-column {
      width: 30%;
      display: block;
      position: relative !important;
      bottom: 20px !important;
      height: 100% !important;
    }
    // .media-column {
    //   width: 30%;
    //   display: block;
    //   height: 100vh !important;
    //   position: relative !important;
    //   top: 20px !important;
    // }
    .info-column {
      padding: 10px;
      // position: relative !important;
      // bottom: 30px !important;
    }
    .media-column .product-images ul {
      width: 90%;
    }
  }
}
